
import BackgroundVideoPlayer from "@/components/BackgroundVideoPlayer"
import { allowKeypress } from "@evercam/shared/utils"
import Vue from "vue"
import { ValidationObserver } from "vee-validate"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import ConnectWithSSOProviderButton from "@/components/users/ConnectWithSSOProviderButton"
import Autocomplete from "@evercam/shared/components/Autocomplete"
import { mapStores } from "pinia"
import { useLayoutStore } from "@/stores/layout"
import { useCameraStore } from "@/stores/camera"
import { LoginResponsePayload, SsoProvider } from "@evercam/shared/types"
import { useAccountStore } from "@/stores/account"
import UserPersonaField from "@/components/persona/UserPersonaField"
import axios from "@evercam/shared/api/client/axios"

export default Vue.extend({
  components: {
    ConnectWithSSOProviderButton,
    Autocomplete,
    BackgroundVideoPlayer,
    UserPersonaField,
  },
  layout: "clean",
  middleware: "unauth",
  async asyncData({ query, app }) {
    let shareRequest = null
    if (query.key) {
      try {
        const { shareRequests } = await EvercamApi.shares.getShareRequestByKey(
          query.key as string
        )
        shareRequest = shareRequests[0]
      } catch (error) {
        app.$notifications.error({
          text: app.i18n.t("content.shares.fetch_share_request_failed"),
          error,
        })
      }
    }
    let key = ""
    let email = ""
    let referralUrl = ""
    let isDisabled = false

    if (shareRequest) {
      key = shareRequest.id
      email = shareRequest.email
      isDisabled = true
      referralUrl = `${shareRequest.sharerEmail} shared camera ${shareRequest.cameraId}`
    }

    return {
      shareRequest: shareRequest,
      isDisabled: isDisabled,
      model: {
        firstname: "",
        lastname: "",
        email: email,
        password: "",
        token: "",
        telephone: "",
        shareRequestKey: key,
        referralUrl: referralUrl,
        persona: "",
      },
    }
  },
  data: () => ({
    shareRequest: null,
    isDisabled: false,
    model: {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      token: "",
      telephone: "",
      shareRequestKey: "",
      referralUrl: "",
      persona: "",
      disablePassword: false,
    },
    countries: require("~/static/country-phone-code.json"),
    telephonePrefix: null,
    toggleValue: false,
    loading: false,
    ssoProvider: SsoProvider,
  }),
  meta: {
    public: true,
  },
  head() {
    return {
      title: this.$t("pages.sign_up").toString(),
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useLayoutStore, useCameraStore, useAccountStore),
  },
  methods: {
    checkEmail() {
      const email = this.model.email
      const emailDomain = email.substring(email.lastIndexOf("@"))
      // if email contains @sljv.ie or any SSO restricted company domain, let user know they should use SSO
      if (
        [
          "@sljv.ie",
          "@daa.ie",
          "@dublinairport.com",
          "@pjhegarty.ie",
          "@ecs.construction",
          "@hfa-ae.com",
          "@compassdatacenters.com",
          "@lemartec.com",
          "@mastec.com",
          "@miamifreedompark.com",
          "@aster.com.sg",
          "@gray.com",
          "@grayaes.com",
          "@nexgengc.com",
          "@infrapartners.llc",
        ].includes(emailDomain)
      ) {
        // Empty and Disable password field
        this.model.password = ""
        this.model.disablePassword = true

        return "You have to use the SSO button above to Signup."
      } else {
        this.model.disablePassword = false

        return true
      }
    },
    async preformSignup() {
      const form = {
        ...this.model,
        telephone:
          this.telephonePrefix?.dialCode +
          this.model.telephone.replace(/^0/g, ""),
        token: `${this.$config.public.webAppToken}`,
      }

      this.layoutStore.loader = true
      try {
        const data: LoginResponsePayload = await EvercamApi.users.signup(form)
        axios.setToken(data.token as string)
        this.accountStore.updateUser(data)
        this.accountStore.token = data.token
        this.cameraStore.fetchUserCameras()
        this.layoutStore.loader = false
        const observer = this.$refs.obs as InstanceType<
          typeof ValidationObserver
        >
        observer.reset()
        this.$router.push("/v2/projects")
      } catch (error) {
        this.layoutStore.loader = false
        this.$notifications.error({
          text: this.$t("content.auth.signup_failed"),
          error,
        })
      }
    },
    toggleIcon() {
      this.toggleValue = !this.toggleValue
    },
    allowKeypress,

    telephonePrefixCustomFilter(item, queryText) {
      const textOne = item.name.toLowerCase()
      const textTwo = item.code.toLowerCase()
      const textThree = item.dialCode.toLowerCase()
      const searchText = queryText.toLowerCase()

      return (
        textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1 ||
        textThree.indexOf(searchText) > -1
      )
    },
  },
})
